import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap
} from "../components/elements/BlockWithIcon";
import SliderPageHeader from "../components/elements/SliderPageHeader";
import ButtonLink from "../components/elements/ButtonLink";

const Dashboard = () => (
  <Index>
    <Seo title="Dashboard" />
    <SliderPageHeader id="slide-3-inner" extraContainerClasses="content-right">
      <h1 className="font-light">
        Dashboard Analytics <br />
        <b>at Your Fingertips.</b>
      </h1>
      <p>
        Real-time access to your patients' <br />
        status and savings.
      </p>
      <footer>
        <ButtonLink href="/request-demo/" text="Schedule a Call" type="primary" />
      </footer>
    </SliderPageHeader>
    <Container>
      <BlockWithIconWrap>
        <p className="lead font-light text-blue">
          <br />
          InCircle’s data-driven model collects the development of care patterns and <br className="hide-large" />
          provides recognition of how patients are cared for across the scope of practice. <br /><br />

          The InCircle model recognizes patient cases that might not respond to therapy, <br className="hide-large" />
          calculates the cost for these cases and proposes strategies to reduce costs, <br className="hide-large" />
          to enable more effective utilization  of  resources.
          <br />&nbsp;
        </p>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="checkmark-ribbon" />
          <BlockWithIconText>
            <h2>Insider Access to Industry <br />Expertise</h2>
            <p>
              As an InCircle Review participant, you’ll experience a new and quick way <br />
              of working with proven industry experts across a wide range of specialties. <br />
              This access includes the data points that led to the prescribing decision, <br />
              along with a summary of findings to help educate the healthcare provider, <br />
              the health insurer and the patient.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="target" />
          <BlockWithIconText>
            <h2>Longitudinal Assessments <br />Help Increase Accuracy</h2>
            <p>
              InCircle takes the guesswork out of the decision-making process, leading to <br />
              increased prescribing accuracy. The data-driven model allows for the <br />
              development of care patterns, and the algorithm-based approach is limited to <br />
              only the most vital information. This collection of data is collected in naïve <br />
              patients, as well as patients over time initially deemed confusion. <br />
              Collecting all the longitudinal data points allows InCircle to continually <br />
              evaluate how a patient will do on therapy.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="doctor" />
          <BlockWithIconText>
            <h2>Advantages for Healthcare Providers, <br />Health Insurance Payers and Employers</h2>
            <p>
              By leveraging the experience and knowledge of a panel of expert reviewers <br />
              who have access to real data, InCircle alleviates the burden on prescribing <br />
              physicians. It also gives health insurers a transparent and consistent view <br />
              of the decision-making process, while providing employers with a desperately <br />
              needed solution to control drug spend.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <p>&nbsp;</p>
      </BlockWithIconWrap>
    </Container>
  </Index>
)

export default Dashboard
